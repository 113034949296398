import React from 'react'
import Footer from "../Footer/Footer";
import SideBar from "../SideBar";
import {Link} from 'react-router-dom';
import Header from '../Header/Header';
import shopicon from "../../assets/crImages/Vector.png";
import top_met from "../../assets/crImages/BG_ImageTopLeft.png";
import right_met from "../../assets/crImages/BG_ImageTopRight.png";
import bottm_met from "../../assets/crImages/BG_ImageBottom.png";
const StoreConfiguration = () => {
  //  let name;
  //  console.log(name,"KKKKKK")
 
  // const nestedArray = [1, [2, 3], [4, [5, 6]], 7];
  //  for(let i=0; nestedArray.length; i++) {
  //     // console.log(i)
  //  } 

  return (
    <div>
    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        <div className="d-flex flex-column flex-root  new_meyt">
          <div className="page d-flex flex-row flex-column-fluid">
            <div className="container">
              <div className="row">
                <div className="col-2">
                  <SideBar />
                </div>
                <div className="col-10">
                  <div
                    className="wrapper d-flex flex-column flex-row-fluid"
                    id="kt_wrapper"
                  >
                    <Header />
                    <div
                      className="content d-flex flex-column flex-column-fluid ps-0"
                      id="kt_content"
                    >  
                    <div className='BackArrow'>
                        <Link to="/storesListing">Back</Link>
                     </div>
                      <div
                        className="post d-flex flex-column-fluid"
                        id="kt_post"
                      >
                        
                        <div
                          id="kt_content_container"
                          className="container-fluid confi"
                        >
                          <div className="card border">
                            <div className="card-header ">
                              <div className="col-sm-12 col-md-12">
                                <div className="shop_icon">
                                  {/* <img src={shopicon} /> */}
                                  <i class="fa-solid fa-gears"></i>
                                </div>
                                <div className="met_in_hed">
                                  <h3 className="text-dark fw-bold my-1">
                                  Store configuration
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div>
                              <form id="connectionForm">
                                {/* <div className="form-group row feedForm1 justify-content-center">
                                  <div className="col-sm-12">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="inputShopname"
                                      placeholder="Productd Setting "
                                    />
                                  </div>
                                </div> */}

                                 <div className="form-group row feedForm1 justify-content-center mb-5">
                                    <div className="col-sm-12">
                                      <div className="row align-items-center">
                                        <div className="col-md-4 text-md-right">
                                          <label
                                            htmlFor="inputShopname"
                                            className="col-form-label inputShopname"
                                          >
                                            Productd Setting
                                          </label>
                                        </div>
                                        <div className="col-md-8">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="inputShopname"
                                            placeholder="Productd Setting"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                 <div className="form-group row feedForm1 justify-content-center mb-5">
                                    <div className="col-sm-12">
                                      <div className="row align-items-center">
                                        <div className="col-md-4 text-md-right">
                                          <label
                                            htmlFor="inputShopname"
                                            className="col-form-label inputShopname"
                                          >
                                            Order Setting
                                          </label>
                                        </div>
                                        <div className="col-md-8">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="inputShopname"
                                            placeholder="Order Setting"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                           
                             
                             
                                {/* <div className="form-group row feedForm justify-content-center">
                                  <div className="col-sm-12">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="inputapiKey"
                                      placeholder="Order Setting"
                                    />
                                  </div>
                                </div> */}
                               <div className="form-group row feedForm1 justify-content-center">
                                    <div className="col-sm-12">
                                      <div className="row align-items-center">
                                        <div className="col-md-4 text-md-right">
                                          {/* <label htmlFor="inputShopname" className="col-form-label inputShopname">
                                          Conncurrency
                                          </label> */}
                                        </div>
                                        <div className="col-md-8">
                                          <button
                                            type="submit"
                                            id="kt_sign_in_submit"
                                            className="btn-connection mb-5"
                                            // onClick={this.connection}
                                            style={{
                                              marginLeft: "10px",
                                              background: "#f08303",
                                              color: "#fff",
                                              padding: "10px",
                                            }}
                                          >
                                            <span className="indicator-label ">
                                              Submit
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              
                              </form>
                            </div>
                            <div className="top_met">
                              <img src={top_met} />
                            </div>
                            <div className="right_met">
                              <img src={right_met} />
                            </div>
                            <div className="bottom_met">
                              <img src={bottm_met} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  </div>
  )
}

export default StoreConfiguration