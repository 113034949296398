import React from 'react';
import { withRouter } from "react-router-dom";
import Leftsidebar from "../component/leftsidebar";
import Header from "../component/header";
import Footer from "../component/footer";
import Foot from "../component/foot";
import '../index.css';
import axios from 'axios';
import Loader from "../Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userActions } from '../redux/actions';

class Configure extends React.Component {
    constructor(props) {
        super(props);
        this.configureDetail();
        this.state = {
            manual: true, 
            automatic: false, 
            inventory: true, 
            stock: false, 
            purchase: false, 
            tracking: false, 
            loader: false, 
            a_value: "", 
            a_type: "", 
            stock_value: 0, 
            stock_time: 0, 
            purchase_value: 0, 
            purchase_time: 0, 
            tracking_value: 0, 
            tracking_time: 0, 
            rec_s : false, 
            rec_p : false, 
            rec_t : false, 
            siteId: "",
            platformsiteid: ""
        }
    }


    configureDetail = async () => {
        try {
            var data;
            var token_ = sessionStorage.getItem("token");
            var user_ = sessionStorage.getItem("user");
            var data = {
                user : user_
            }
            this.setState({ loader: true })

            await axios.get(`${process.env.REACT_APP_API_platformX}/api/platform/site`).then(async (platformsite) => {
                if(platformsite.data && platformsite.data.list && platformsite.data.list.length && platformsite.data.list[0] && platformsite.data.list[0].siteId){
                    this.setState({ platformsiteid: platformsite.data.list[0].siteId })
                }
            }).catch((err) => {
            })
                await axios.post(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/configure/detail`, data, {
                    headers: {
                        'authorization': (token_ ? token_ : "")
                    }
                }).then((res) => {
                    if (res.data.msg.id) {
                        this.setState({ loader: false})
                        if (res.data.msg.message.inventory.schedule.mode == 'manual') {
                            this.setState({ 
                                manual: true, 
                                automatic: false, 
                                a_type: res.data.msg.message.inventory.schedule.type, 
                                a_value: res.data.msg.message.inventory.schedule.value.replaceAll("'", "").toString()
                            });

                            document.getElementById("a_value").value = res.data.msg.message.inventory.schedule.value
                        }
                        else {
                            this.setState({ 
                                manual: false, 
                                automatic: true, 
                                a_type: res.data.msg.message.inventory.schedule.type, 
                                a_value: res.data.msg.message.inventory.schedule.value.replaceAll("'", "").toString()
                            });
                            document.getElementById("a_value").value = res.data.msg.message.inventory.schedule.value
                        }
                        if(res.data.msg.message.stock == "1"){
                            this.setState({ stock_value: 1 })
                        }
                        if(res.data.msg.message.purchase == "1"){
                            this.setState({ purchase_value: 1 })
                        }
                        if(res.data.msg.message.tracking == "1"){
                            this.setState({ tracking_value: 1 })
                        }
                        if(res.data.msg.message.stock_time){
                            this.setState({ stock_time: res.data.msg.message.stock_time.replaceAll("'", "").toString() })
                            document.getElementById("stock_time").value = res.data.msg.message.stock_time
                        }
                        
                        if(res.data.msg.message.purchase_time){
                            this.setState({ purchase_time: res.data.msg.message.purchase_time.replaceAll("'", "").toString() })

                            document.getElementById("purchase_time").value = res.data.msg.message.purchase_time
                        }
                        if(res.data.msg.message.tracking_time){
                            this.setState({ tracking_time: res.data.msg.message.tracking_time.replaceAll("'", "").toString() })

                            document.getElementById("tracking_time").value = res.data.msg.message.tracking_time
                        }
                        if(res.data.msg.siteId){
                            this.setState({ siteId: res.data.msg.siteId })
                        }
                    } else {
                        this.setState({ loader: false, c_data: null })
                    }
                }).catch((error) => {

                    console.log("error", error)
                    this.setState({ loader: false, c_data: null })
                    
                    sessionStorage.removeItem("token");
                    sessionStorage.removeItem("refresh_token");
                    sessionStorage.removeItem("user");
                    sessionStorage.removeItem("connection");
                    window.location.href = "/login";
                    return ({ message: error })
                })
        } catch (error) {
            this.setState({ loader: false, c_data: null })
                    
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("refresh_token");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("connection");
            window.location.href = "/login";
        }
    }

    changeModes(event){
        if (event.target.value == "daily" || event.target.value == "weekly") {
            this.setState({ manual: true, automatic: false });
        }
        else{
            this.setState({ manual: false, automatic: true });
        }
        this.setState({ a_type: event.target.value })
    }

    changeMode(event) {
        if (event.target.value == "inventory") {
            this.setState({ inventory: true, stock: false, purchase: false, tracking: false, manual: true })
        }
        if (event.target.value == "stock") {
            this.setState({ inventory: false, stock: true, purchase: false, tracking: false })
        }
        if (event.target.value == "purchase") {
            this.setState({ inventory: false, stock: false, purchase: true, tracking: false })
        }
        if (event.target.value == "tracking") {
            this.setState({ inventory: false, stock: false, purchase: false, tracking: true })
        }
    }

    submit = async (e) => {
        try {
            var data;
            e.preventDefault();
            this.setState({ loader: true });
            var token_ = sessionStorage.getItem("token");
            var user_ = sessionStorage.getItem("user");
            var platfrom_ = sessionStorage.getItem("platform");
            var connection_ = JSON.parse(sessionStorage.getItem("connection"));
            var data = {
                stock_value: this.state.stock_value,
                purchase_value: this.state.purchase_value,
                tracking_value: this.state.tracking_value,
                stock_time: this.state.stock_time,
                purchase_time: this.state.purchase_time,
                tracking_time: this.state.tracking_time,
                type: (this.state.manual == true ? this.state.a_type : ""),
                value: (this.state.manual == true ? this.state.a_value : ""),
                user: user_,
                platform: platfrom_,
                siteId: this.state.siteId,
                platformsiteid: this.state.platformsiteid,
            }
            debugger
            if(this.state.platformsiteid){
                await axios.post(`${process.env.REACT_APP_API_platformX}/api/platform/site`, { siteId: this.state.platformsiteid }, {
                    headers: {
                        'authorization': (token_ ? token_ : "")
                    }
                }).then(async (platformsite) => {
                }).catch((err) => {
                })
            }
            await axios.post(`${process.env.REACT_APP_API_platformX}/api/check/connection`, { "shop": connection_.store_url, "tok": connection_.api_secret }, {
                headers: {
                    'authorization': (token_ ? token_ : "")
                }
            })
            .then(async (conn) => {
                    // if(conn && conn.shop && conn.shop.id){
                    //     data.store_id = conn.data.shop.id
                    // }
                    // else{
                    //     data.store_id = null
                    // }
                    data.store_id = connection_.store_url
                    data.platform_url = process.env.REACT_APP_API_platformX
                    await axios.post(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/configure`, data, {
                        headers: {
                            'authorization': (token_ ? token_ : "")
                        }
                    })
                        .then((res) => {
                            if (res.data.msg.id) {
                                try {
                                    toast.success(res.data.msg.message);
                                    this.setState({ loader: false });
                                    this.props.history.push('/configure');

                                } catch (error) {
                                    return ({ message: error })
                                }
                            } else {
                                toast.error(res.data.msg.message)
                                this.setState({ loader: false });

                            }
                        }).catch((err) => {
                            toast.error("something happened")
                            this.setState({ loader: false });
                        })
                    }).catch((err) => {
                        toast.error("something happened")
                        this.setState({ loader: false });
                    })

        } catch (error) {
            return ({ message: error })
        }
    }

    render() {
        return <>
            <div className="d-flex flex-column flex-root config_set_page">
                <div className="page d-flex flex-row flex-column-fluid">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-2'>
                                <Leftsidebar />
                            </div>
                            <div className='col-10'>
                                <div
                                    className="wrapper d-flex flex-column flex-row-fluid"
                                    id="kt_wrapper"
                                >
                                    <Header />
                                    <div
                                        className="content d-flex flex-column flex-column-fluid ps-0 "
                                        id="kt_content"
                                    >
                                        <div className="post d-flex flex-column-fluid" id="kt_post">
                                            <div id="kt_content_container" className="container-fluid confi">
                                                <div className="card border b-div">
                                                    <div className="card-header">
                                                        <div className="col-sm-12 col-md-12 ">
                                                            <h3 className="text-dark fw-bold my-1">Configure Feed</h3>
                                                            {/* <p>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                        </p> */}
                                                        </div>
                                                    </div>
                                                    <div className="rule-section">
                                                        <div className="row sche-sec2">
                                                            <div className="col sche-sec2-p1">
                                                                <div className="row ">
                                                                    <div className="col stack">
                                                                        <div className={(this.state.inventory == true ? "form-check form-check-inline active" : "form-check form-check-inline")}>
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inputInventory" value="inventory" onClick={() => this.changeMode(event)} />
                                                                            <label className="form-check-label" for="inputInventory">
                                                                                <div>
                                                                                    Inventory
                                                                                </div>
                                                                                <i className="fa-solid fa-clipboard-check"></i>
                                                                            </label>

                                                                        </div>
                                                                        <div className={(this.state.stock == true ? "form-check form-check-inline active" : "form-check form-check-inline")}>
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inputStock" value="stock" onClick={() => this.changeMode(event)} />
                                                                            <label className="form-check-label" for="inputStock">
                                                                                <div>
                                                                                    Stock
                                                                                </div>
                                                                                <i className="fa-sharp fa-solid fa-file-invoice"></i>
                                                                            </label>

                                                                        </div>
                                                                        <div className={(this.state.purchase == true ? "form-check form-check-inline active" : "form-check form-check-inline")}>
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inputPurchase" value="purchase" onClick={() => this.changeMode(event)} />
                                                                            <label className="form-check-label" for="inputPurchase">
                                                                                <div>
                                                                                    Purchase Orders
                                                                                </div>
                                                                                <i className="fa-solid fa-cart-shopping"></i>
                                                                            </label>
                                                                        </div>
                                                                        <div className={(this.state.tracking == true ? "form-check form-check-inline active" : "form-check form-check-inline")}>
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inputTracking" value="tracking" onClick={() => this.changeMode(event)} />
                                                                            <label className="form-check-label" for="inputTracking">
                                                                                <div>
                                                                                    Tracking
                                                                                </div>
                                                                                <i className="fa-solid fa-map-location-dot"></i>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="sche-body" style={{ display: (this.state.inventory == true ? "flex" : "none") }}>
                                                            <div className="row ">
                                                                <div className="col">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inputManual" value="manual" onClick={() => this.changeMode(event)} checked={this.state.manual} />
                                                                            <label className="form-check-label" for="inlineRadio1">Manual</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline" >
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inputAutomatic" value="automatic" onClick={() => this.changeMode(event)} checked={this.state.automatic} />
                                                                            <label className="form-check-label" for="inlineRadio2">Automatic</label>
                                                                        </div>
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                        <div className="row inn_mert" style={{ display: (this.state.inventory == true ? "flex" : "none") }}>
                                                            <div className="col sche-sec2-p1" >
                                                                {/* <div className="info-icon">
                                                                    <svg className="svg-inline--fa fa-circle-info  info-ic" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"></path></svg>
                                                                </div> */}
                                                                {/* <div className="info-text" style={{ display: (this.state.manual == true ? "inline-block" : "none") }}>
                                                                    You have selected manual option. A button will be added to the feed page. You can click the button to run the feed whenever required.
                                                                </div>
                                                                <div className="info-text" style={{ display: (this.state.automatic == true ? "inline-block" : "none") }}>
                                                                    You have selected automatic option. Configure the feed schedule below.
                                                                </div> */}
                                                                <div className="row auto-box">
                                                                    <div className="col-12">
                                                                        <p style={{ textAlign: 'start', marginBottom: '20px', fontSize: '20px' }}>Run the feed automatically once every
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="row">
                                                                            <div className="col-3">
                                                                                <select className="form-control col-md-3 automatic_fields" name="schedule_interval" id="schedule_interval" required="required" onChange={e => this.changeModes(event)} 
                                                                                value={this.state.a_type}
                                                                                >
                                                                                    <option value="select">Select</option>
                                                                                    <option value="daily">Daily</option>
                                                                                    <option value="weekly">Weekly</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <input className="form-input time-input" type="time" id="a_value" name="a_value"  onChange={e =>
                                                                                    this.setState({ a_value: e.target.value})
                                                                                } 
                                                                                checked={this.state.a_value}/>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="row">
                                                                            <div className="col-2" >
                                                                                <p style={{ textAlign: 'start', marginTop: '16px', marginBottom: '6px', fontSize: '14px' }}>Site Id
                                                                                </p>
                                                                                <input type="text" className="form-control col-md-3 automatic_fields" id="siteId" name="siteId" placeholder="Site Id" required="required" onChange={e => this.setState({ siteId: e.target.value })} value={this.state.siteId}/>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="row">
                                                                            <div className="col-2" >
                                                                                <p style={{ textAlign: 'start', marginTop: '16px', marginBottom: '6px', fontSize: '14px' }}>Site Id
                                                                                </p>
                                                                                <input type="text" className="form-control col-md-3 automatic_fields" id="platformsiteid" name="platformsiteid" placeholder="Site Id" required="required" onChange={e => this.setState({ platformsiteid: e.target.value })} value={this.state.platformsiteid}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row " style={{ display: (this.state.stock == true ? "flex" : "none"), marginTop: '40px' }}>
                                                            <div className="col sche-sec2-p1">
                                                                <div className="row ">
                                                                    <div className="col-4">
                                                                        <div className='switch_group'>
                                                                            <div className="form-check form-switch">
                                                                                <label className="form-check-label text-left col-11 diff" htmlFor="defaultCheck1">
                                                                                    Stock
                                                                                </label>
                                                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" name="stock"  onChange={e =>
                                                                                    this.setState({ stock_value: (e.target.checked ? 1 : 0)})
                                                                                } 
                                                                                checked={this.state.stock_value}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <input className="form-input time-input" type="time" id="stock_time" name="stock_time"  onChange={e =>
                                                                            this.setState({ stock_time: e.target.value})
                                                                        } />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row" style={{ display: (this.state.purchase == true ? "flex" : "none"), marginTop: '40px' }}>
                                                            <div className="col sche-sec2-p1">
                                                                <div className="row ">
                                                                    <div className="col-4">
                                                                        <div className='switch_group'>
                                                                            <div className="form-check form-switch">
                                                                                <label className="form-check-label text-left col-11 diff" htmlFor="defaultCheck1">
                                                                                    Purchase
                                                                                </label>
                                                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" name="purchase" onChange={e => this.setState({ purchase_value: (e.target.checked ? 1 : 0)  })} 
                                                                                checked={this.state.purchase_value}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <input className="form-input time-input" type="time" id="purchase_time" name="purchase_time"  onChange={e =>
                                                                            this.setState({ purchase_time: e.target.value})
                                                                        } />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row" style={{ display: (this.state.tracking == true ? "flex" : "none"), marginTop: '40px' }}>
                                                            <div className="col sche-sec2-p1">
                                                                <div className="row ">
                                                                    <div className="col-4">
                                                                        <div className='switch_group'>
                                                                            <div className="form-check form-switch">
                                                                                <label className="form-check-label text-left col-11 diff" htmlFor="defaultCheck1">
                                                                                    Tracking
                                                                                </label>
                                                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" name="tracking" onChange={e => this.setState({ tracking_value: (e.target.checked ? 1 : 0)})} 
                                                                                checked={this.state.tracking_value}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <input className="form-input time-input" type="time" id="tracking_time" name="tracking_time"  onChange={e =>
                                                                            this.setState({ tracking_time: e.target.value})
                                                                        } />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="rule-footer">
                                                            <div className="row sche-save">
                                                                <div className="col">
                                                                    <button type="save" id="kt_sign_in_save" style={{ background: "#D92E3A", color: "#fff", marginTop: '40px', padding: '13px 30px' }} className="btn-connection btn-save mb-2" onClick={this.submit}>
                                                                        <span className="indicator-label" style={{ background: "#D92E3A", color: "#fff" }}>SAVE</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                    {this.state.loader == true ? <Loader /> : ''}
                </div>
                <Footer />
            </div>
            <Foot />
        </>
    }
}

export default withRouter(Configure);
