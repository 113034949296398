import React from 'react';
import { Link, withRouter } from "react-router-dom";
import '../index.css';
import axios from "axios";
import Loader from "../Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../assets/crImages/Logo.png";

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = { firstname: "", lastname: "", email: "", password: "", companyname: "", phone: "", loader: false, formErrors: { static: null } };
        this.initialState = this.state;
    }

    handleFormValidation() {
        const { firstname, lastname, email, password, companyname, phone } = this.state;
        let formErrors = {};
        let formIsValid = true;

        //Student name     
        if (!firstname && firstname.length < 4) {
            formIsValid = false;
            formErrors["firstnameErr"] = "Firstname is required.";
        }

        //Student name     
        if (!lastname && lastname.length < 4) {
            formIsValid = false;
            formErrors["lastnameErr"] = "Lastname is required.";
        }

        //Email    
        if (!email) {
            formIsValid = false;
            formErrors["emailErr"] = "Email is required.";
        }
        else if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))) {

            formIsValid = false;
            formErrors["emailErr"] = "Invalid email id.";
        }

        //Phone number    
        if (!phone) {
            formIsValid = false;
            formErrors["phoneErr"] = "Mobile no. is required.";
        }

        //Password    
        if (!password) {
            formIsValid = false;
            formErrors["password"] = "Password is required.";
        }

        this.setState({ formErrors: formErrors });
        return formIsValid;
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    Submit = async (e) => {
        e.preventDefault();
        if (this.handleFormValidation()) {
            this.setState({ loader: true });
            try {
                var data;
                await axios.get('https://api.ipify.org/?format=json')
                    .then((resp) => {
                        if (resp.data.ip) {
                            data = { firstname: this.state.firstname, lastname: this.state.lastname, companyname: this.state.companyname, phone: this.state.mobile, email: this.state.email, password: this.state.password, created_ip: resp.data.ip };
                        } else {
                            data = { firstname: this.state.firstname, lastname: this.state.lastname, companyname: this.state.companyname, phone: this.state.mobile, email: this.state.email, password: this.state.password, created_ip: "" };
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                // console.log(`${process.env.REACT_APP_API_ENDPOINT}/user/signup`)
                await axios.post(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/signup`, data)
                    .then((res) => {
                        if (res.data.msg.id) {
                            try {

                                toast.success(res.data.msg.message)
                                this.setState({loader : false});
                                this.props.history.push('/');
                            } catch (error) {
                                return ({ message: error })
                            }
                        } else {
                            toast.error(res.data.msg)
                            this.setState({loader : false});

                        }
                    }).catch((err) => { console.log('error', err) })

            } catch (error) {
                alert("Login Failed")
            }
        }
    }

    render() {
        return <>
            <div className="d-flex flex-column flex-root login_section">
                <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                    <div className='logo_met_in'>
                        <Link to="/connection" className="brandName" style={{ color: "#ff8c00" }}>
                            <img src={logo} />
                        </Link>
                    </div>
                    <div className="d-flex flex-center flex-column flex-column-fluid p-10">
                        <div className="w-lg-500px bg-body rounded shadow-sm p-5 p-lg-15 mx-auto">
                            <form
                                className="form w-100"
                                noValidate="novalidate"
                                id="kt_sign_in_form"
                                action="products.php"
                                method="post"
                            >
                                <div className="text-center mb-10">
                                    <h1 className="text-dark mb-3 fw-semibold">Sign Up</h1>
                                    <h2>Already a user, Click here to <Link to="/" className="mb-10">Login</Link></h2>
                                </div>
                                <div className="fv-row mb-10">
                                    <label className="form-label fs-6 fw-bold text-dark">
                                        FirstName<span style={{ fontSize: "11px", color: "red" }}> *</span>
                                    </label>
                                    <input
                                        className="form-control form-control-lg form-control-solid"
                                        type="text"
                                        name="firstname"
                                        onChange={this.handleChange}
                                        autoComplete="off" required
                                    />
                                    {this.state.formErrors.firstnameErr ?
                                        <> <div style={{ marginTop: "5px", color: "red" }}>{this.state.formErrors.firstnameErr}</div>
                                        </> : <></>}
                                </div>
                                <div className="fv-row mb-10">
                                    <label className="form-label fs-6 fw-bold text-dark">
                                        LastName<span style={{ fontSize: "11px", color: "red" }}> *</span>
                                    </label>
                                    <input
                                        className="form-control form-control-lg form-control-solid"
                                        type="text"
                                        name="lastname"
                                        onChange={this.handleChange}
                                        autoComplete="off" required
                                    />{this.state.formErrors.lastnameErr ?
                                        <> <div style={{ marginTop: "5px", color: "red" }}>{this.state.formErrors.lastnameErr}</div>
                                        </> : <></>}
                                </div>
                                <div className="fv-row mb-10">
                                    <label className="form-label fs-6 fw-bold text-dark">
                                        Company Name<span style={{ opacity: 0.4, fontSize: "11px" }}> (Optional)</span>
                                    </label>
                                    <input
                                        className="form-control form-control-lg form-control-solid"
                                        type="text"
                                        name="companyname"
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="fv-row mb-10">
                                    <label className="form-label fs-6 fw-bold text-dark">
                                        Mobile<span style={{ fontSize: "11px", color: "red" }}> *</span>
                                    </label>
                                    <input
                                        className="form-control form-control-lg form-control-solid"
                                        type="tel"
                                        name="phone"
                                        onChange={this.handleChange}
                                        autoComplete="off" required
                                    />
                                    {this.state.formErrors.phoneErr ?
                                        <> <div style={{ marginTop: "5px", color: "red" }}>{this.state.formErrors.phoneErr}</div>
                                        </> : <></>}
                                </div>
                                <div className="fv-row mb-10">
                                    <label className="form-label fs-6 fw-bold text-dark">
                                        Email<span style={{ fontSize: "11px", color: "red" }}> *</span>
                                    </label>
                                    <input
                                        className="form-control form-control-lg form-control-solid"
                                        type="text"
                                        name="email"
                                        onChange={this.handleChange}
                                        autoComplete="off" required

                                    />{this.state.formErrors.emailErr ?

                                        <> <div style={{ marginTop: "5px", color: "red" }}>{this.state.formErrors.emailErr}</div>
                                        </> : <></>}
                                </div>
                                <div className="fv-row mb-10">
                                    <div className="d-flex flex-stack mb-2">
                                        <label className="form-label fw-bold text-dark fs-6 mb-0">
                                            Password<span style={{ fontSize: "11px", color: "red" }}> *</span>
                                        </label>
                                        {/* <a href="#" className="link-primary fs-6 fw-bold">
                                        Forgot Password ?
                                    </a> */}
                                    </div>
                                    <input
                                        className="form-control form-control-lg form-control-solid"
                                        type="password"
                                        name="password"
                                        onChange={this.handleChange}
                                        autoComplete="on" required
                                    />
                                    {this.state.formErrors.password ?
                                        <> <div style={{ marginTop: "5px", color: "red" }}>{this.state.formErrors.password}</div>
                                        </> : <></>}
                                </div>
                                <div className="text-center">
                                    <button
                                        type="button"
                                        id="kt_sign_in_submit"
                                        className="btn btn-lg btn-primary w-100 m2-5" onClick={this.Submit}
                                        style={{ backgroundColor: '#D92E3A' }}
                                    >
                                        <span className="indicator-label">Continue</span>
                                        <span className="indicator-progress">
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer />
                {this.state.loader == true ? <Loader /> : ''}
            </div>
        </>
    }
}

export default withRouter(SignUp);