import React from 'react';
import { withRouter } from "react-router-dom";
import Leftsidebar from "../component/leftsidebar";
import Header from "../component/header";
import Footer from "../component/footer";
import Foot from "../component/foot";
import '../index.css';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Loader from "../Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = { orders: [], loader: true, currentPage: 0, perPage: 10 }
        this.orderList();
    }

    orderList = async () => {
        try {
            var token_ = sessionStorage.getItem("token");
            var conn_ = sessionStorage.getItem("connection");
            if (conn_) {
                var connection = JSON.parse(conn_);
            }
            this.setState({ loader: true })
            await axios.post(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/order`, connection, {
                headers: {
                    'authorization': (token_ ? token_ : "")
                }
            }).then((res) => {
                if (res.data.data.length != 0) {
                    console.log(res.data.data, "data response")
                    this.setState({ loader: false, orders: res.data.data })
                } else {
                    this.setState({ loader: false, orders: [] })
                }
            }).catch((error) => {
                this.setState({ loader: false })
                return ({ message: error })
            })
        } catch (error) {
            return ({ message: error })
        }
    }

    render() {
        return <>
            <div className="d-flex flex-column flex-root config_set_page">
                <div className="page d-flex flex-row flex-column-fluid">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-2'>
                                <Leftsidebar />
                            </div>
                            <div className='col-10'>
                                <div
                                    className="wrapper d-flex flex-column flex-row-fluid"
                                    id="kt_wrapper"
                                >
                                    <Header />
                                    <div
                                        className="content d-flex flex-column flex-column-fluid ps-0 "
                                        id="kt_content"
                                    >
                                        <div className="post d-flex flex-column-fluid" id="kt_post">
                                            <div id="kt_content_container" className="container-fluid confi">
                                                <div className="card border b-div">
                                                    <div className="card-header">
                                                        <div className="col-sm-12 col-md-12 ">
                                                            <h3 className="text-dark fw-bold my-1">Order List</h3>
                                                        </div>
                                                    </div>
                                                    <div className="rule-section">
                                                        <table
                                                            className="table table-striped align-middle table-row-solid fs-6 gs-7 gy-5"
                                                            id="kt_table_users"
                                                        >

                                                            <thead>
                                                                <tr className="text-start text-dark fw-semibold fs-6 gs-0">
                                                                    <th className="w-200px">Order Name</th>
                                                                    <th className="w-200px">Created Date</th>
                                                                    <th className="w-200px">Contact Email</th>
                                                                    <th className="w-200px">Currency</th>
                                                                    <th className="w-200px">Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="text-gray-600">
                                                                {this.state.orders.length != 0 ? this.state.orders.slice(this.state.currentPage * this.state.perPage, (this.state.currentPage + 1) * this.state.perPage).map((element, index) => {
                                                                    return <>
                                                                        <tr key={index}>
                                                                            <td >{element.name}</td>
                                                                            <td >{element.created_at.split("T")[0]}</td>
                                                                            <td >{element.email}</td>
                                                                            <td >{element.currency}</td>
                                                                            <td >{element.financial_status}</td>

                                                                        </tr>
                                                                    </>
                                                                })
                                                                    :
                                                                    <>
                                                                        {
                                                                            this.state.loader == true ? <></> : <><tr>
                                                                                <td >No Record</td>
                                                                            </tr></>
                                                                        }
                                                                    </>
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="card-footer">
                                                        <p className="me-3">Showing  {this.state.orders.length} records</p>
                                                        <ReactPaginate
                                                            previousLabel={'previous'}
                                                            nextLabel={'next'}
                                                            breakLabel={'...'}
                                                            pageCount={Math.ceil(this.state.orders.length / this.state.perPage)}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={5}
                                                            containerClassName={'pagination'}
                                                            activeClassName={'active'}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                    {this.state.loader == true ? <Loader /> : ''}
                </div>
                <Footer />
            </div>
            <Foot />
        </>
    }
}

export default withRouter(Order);
