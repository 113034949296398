import React from 'react';
import logo from "../../assets/crImages/Logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";

// ../assets/crImages/Logo.png
const Header = () => {

    function myFunc(e) {
        e.preventDefault();
        document.getElementById("myDrop").classList.toggle("show");

        window.onclick = function (event) {
            if (!event.target.matches('.user_se , i , .profile')) {
                var dropdowns = document.getElementsByClassName("dropdown-menu");
                var i;
                for (i = 0; i < dropdowns.length; i++) {
                    var openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        }
    }
    function handleShowSideBar(e) {
        var get_side = document.getElementById("kt_aside");
    
        if (get_side.style.display === "flex") {
            get_side.style.display = "none";
        } else {
            get_side.style.display = "flex";
            get_side.style.background = "white";
            get_side.style.top = "80px";
            // Add a class to trigger the smooth animation
            get_side.classList.add("sidebar-open");
        }
        // var get_side = document.getElementById("kt_aside");
        
        // if (get_side.style.display == "flex") {
        //     get_side.style.display = "none";
        // }else if(get_side.style.display = "block") {
        //     get_side.style.display = "flex";
        //     get_side.style.background = "white";
        //     get_side.style.top = "80px";
        // }
        // else {
        //     get_side.style.display = "block";
        //     get_side.style.background = "white";
        //     get_side.style.top = "80px";
        //     get_side.style.transform = "none" 
            
        //     // get_side.style.display = "flex";
        // }

    }
  return (
    <div id="kt_header" className="header header-bg">
    <div className="container-fluid ">
        <div className='left_area'>
            <div className="header-brand me-5">
                <div
                    className="d-flex align-items-center d-lg-none ms-n2 me-2"
                    title="Show aside menu"
                >
                    <div onClick={handleShowSideBar}
                        className="btn btn-icon btn-color-primary btn-active-color-primary w-30px h-30px"
                        id="kt_aside_toggle"
                    >
                        <span className="svg-icon svg-icon-1">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
                <Link to="/supplliersetting" className="brandName" style={{ color: "#ff8c00" }}>
                    <h1>BRAND</h1>
                {/* <img src={logo} /> */}
                </Link>
            </div>
        </div>
        <div className='right-area Latest'>
            <div className='home_menu'>
                <Link to="/supplliersetting" ><i className="fa-solid fa-house pr-2"></i> DashBoard</Link>
            </div>
            <div className="topbar d-flex align-items-stretch" >
                <div className="d-flex align-items-center me-2 me-lg-4 dropdown">
                    <div 
                    onClick={myFunc}
                     className="btn btn-borderless  btn-active-primary bg-white bg-opacity-10" >
               
                        <div className='user_se'>
                            <i className="fas fa-user-circle"></i>
                            <div className='profile'>
                             Profile
                             </div>
                        </div>
                        
                    </div>
                    <div id="myDrop"
                        className="dropdown-menu menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color py-4 fs-6 w-250px"
                    >
                        {/* <div className="menu-item ">
                        <a href="#" className="menu-link px-5">
                            My Profile
                        </a>
                    </div> */}
                        {/* <div className="menu-item my-1">
                        <a href="#" className="menu-link px-5">
                            Account Settings
                        </a>
                    </div> */}
                        <div className="menu-item ">
                            <a 
                            // onClick={handleLogout} 
                            className="menu-link px-5">
                                Sign Out
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}

export default Header;