import React from 'react'

const Footer = () => {
  return (
    <div>
        <div  style={{backgroundColor:"#f08303", padding:'10px'}} class=" d-flex flex-lg-column " id="kt_app_footer ">
    <div class="app-container  container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3 ">
        <div class="text-dark order-2 order-md-1 CustomTestFooter">
           
            {/* <a href="#" target="_blank" class="text-gray-800 text-hover-primary">
                2023 Brands-Inventory Application
            </a> */}
             <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                <li className='text-gray-900'>
                <span class="text-muted fw-semibold me-1">&copy;</span>
                </li>
            <li class="">
                <a target="_blank" class="menu-link px-2">
                2023 Brands-Inventory Application
                </a>
            </li>
        </ul>
        </div>
        <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
            <li class="">
                <a target="_blank" class="menu-link px-2">
                    Terms & Conditions
                </a>
            </li>
            <li class="">
                <a target="_blank" class="menu-link px-2">
                    Privacy Policy
                </a>
            </li>
        </ul>
    </div>
</div>
<div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
    <span class="svg-icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)"
                fill="currentColor" />
            <path
                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                fill="currentColor" />
        </svg>
    </span>
</div>
    </div>
  )
}

export default Footer