import React from "react";
import SideBar from "../SideBar";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "../Supplier.css"
const Products = () => {
  return (
    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        <div className="container">
          <div className="row">
            <div className="col-2">
              <SideBar />
            </div>
            <div className="col-10">
              <div
                className="wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
              >
                <Header />
                <div
                  class="content d-flex flex-column flex-column-fluid ps-0"
                  id="kt_content"
                >
                  <div class="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" class="container-fluid">
                      <div class="card border">
                        <div class="card-header border-bottom py-4 px-6">
                          <div class="card-title">
                            <div class="customSearchBox me-3">
                              <div class="input-group position-relative">
                                <button
                                  class="btn btn-outline-secondary btn-light border"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  All Products
                                </button>
                                {/* <ul class="dropdown-menu">
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      Products 1
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      Products 2
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      Products 3
                                    </a>
                                  </li>
                                </ul> */}
                                <button
                                  type="submit"
                                  class="btn btn-icon btn-primary customSearchBtn"
                                >
                                  <i class="las la-search fs-1"></i>
                                </button>
                                <input
                                  type="text"
                                  class="form-control border"
                                  aria-label="Text input with dropdown button"
                                  placeholder="Search"
                                />
                              </div>
                            </div>
                            <div class="topDropdownList d-flex gap-3">
                              <select
                                class="form-select form-select-border select2-hidden-accessible"
                                data-control="select2"
                                data-hide-search="true"
                                name="product"
                                data-select2-id="select2-data-10-nfgw1"
                                tabindex="-1"
                                aria-hidden="true"
                                data-kt-initialized="1"
                              >
                                <option
                                  value="1"
                                  data-select2-id="select2-data-12-stp71"
                                >
                                  Collections
                                </option>
                                <option value="1a">Collections 1</option>
                                <option value="1b">Collections 2</option>
                                <option value="1c">Collections 3</option>
                              </select>
                              <select
                                class="form-select form-select-border select2-hidden-accessible me-2"
                                data-control="select2"
                                data-hide-search="true"
                                name="product"
                                data-select2-id="select2-data-10-nfgw2"
                                tabindex="-1"
                                aria-hidden="true"
                                data-kt-initialized="1"
                              >
                                <option
                                  value="2"
                                  data-select2-id="select2-data-12-stp72"
                                >
                                  Vendors
                                </option>
                                <option value="2a">Vendors 1</option>
                                <option value="2b">Vendors 2</option>
                                <option value="2c">Vendors 3</option>
                              </select>
                              <select
                                class="form-select form-select-border select2-hidden-accessible"
                                data-control="select2"
                                data-hide-search="true"
                                name="product"
                                data-select2-id="select2-data-10-nfgw3"
                                tabindex="-1"
                                aria-hidden="true"
                                data-kt-initialized="1"
                              >
                                <option
                                  value="3"
                                  data-select2-id="select2-data-12-stp73"
                                >
                                  Warehouse
                                </option>
                                <option value="3a">Warehouse 1</option>
                                <option value="3b">Warehouse 2</option>
                                <option value="3c">Warehouse 3</option>
                              </select>
                              <select
                                class="form-select form-select-border select2-hidden-accessible"
                                data-control="select2"
                                data-hide-search="true"
                                name="product"
                                data-select2-id="select2-data-10-nfgw4"
                                tabindex="-1"
                                aria-hidden="true"
                                data-kt-initialized="1"
                              >
                                <option
                                  value="4"
                                  data-select2-id="select2-data-12-stp74"
                                >
                                  Select Columns
                                </option>
                                <option value="4a">Select Columns 1</option>
                                <option value="4b">Select Columns 2</option>
                                <option value="4c">Select Columns 3</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="card-header border-bottom py-4 px-6">
                          <div class="col-sm-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
                            <h3 class="text-dark fw-bolder my-1">All Products</h3>
                          </div>
                          <div class="col-sm-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
                            <div class="listingCounter d-flex align-items-center">
                              <div class="dataTables_info me-3">Show</div>
                              <div
                                class="dataTables_length"
                                id="kt_datatable_example_1_length"
                              >
                                <label>
                                  <select
                                    name="kt_datatable_example_1_length"
                                    aria-controls="kt_datatable_example_1"
                                    class="form-select form-select-sm form-select-border"
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                  </select>
                                </label>
                              </div>
                              <div
                                class="dataTables_info ms-3"
                                id="kt_datatable_example_1_info"
                                role="status"
                                aria-live="polite"
                              >
                                Entries
                              </div>
                            </div>
                            {/* <div class="ms-15">
                                            <div class="exportIcons">
                                                <ul>
                                                    <li>
                                                        <img src="assets/crImages/expotIcon1.png" alt="Export" />
                                                    </li>
                                                    <li>
                                                        <img src="assets/crImages/expotIcon2.png" alt="Export"/>
                                                    </li>
                                                    <li>
                                                        <img src="assets/crImages/expotIcon3.png" alt="Export"/>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                          </div>
                        </div>

                        <div class="card-body p-0 table-responsive">
                          <table
                            class="table table-striped align-middle table-row-solid fs-6 gs-7 gy-5 productsMainC stripe row-border order-column"
                            id="productsMain"
                          >
                            <thead>
                              <tr class="text-start text-dark fw-bold fs-6 gs-0">
                       
                                <th>sr. No </th>
                                <th>Product Name </th>
                                <th>Product SKU </th>
                                <th>Price </th>
                                <th>Language </th>
                              </tr>
                            </thead>
                            <tbody class="text-gray-600 fw-semibold">
                              <tr>
                                 <td>1</td>
                                <td>Engme</td>
                                <td>T05990</td>
                                <td>500</td>
                                <td>English</td>                          
                              </tr>
                              <tr>    
                               <td>2</td>
                                <td>Chinm</td>
                                <td>M05990</td>
                                <td>800</td>
                                <td>Chinese</td>
                                      
                              </tr>
                              <tr>    
                               <td>2</td>
                                <td>Jam</td>
                                <td>J05990</td>
                                <td>400</td>
                                <td>Japanese</td>
                                      
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="card-footer">
                          <div class="tablePaginationMain">
                            <p class="m-0">Showing 1 to 10 of 30 records</p>
                            <div class="tablePagination">
                              <nav aria-label="...">
                                <ul class="pagination">
                                  <li class="page-item disabled">
                                    <a class="page-link">Previous</a>
                                  </li>
                                  <li class="page-item">
                                    <a class="page-link" href="#">
                                      1
                                    </a>
                                  </li>
                                  <li
                                    class="page-item active"
                                    aria-current="page"
                                  >
                                    <a class="page-link" href="#">
                                      2
                                    </a>
                                  </li>
                                  <li class="page-item">
                                    <a class="page-link" href="#">
                                      3
                                    </a>
                                  </li>
                                  <li class="page-item">
                                    <a class="page-link" href="#">
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </div>
      <Footer />
    </div>
  );
};

export default Products;
