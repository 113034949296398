import React from 'react'
import { Link } from "react-router-dom";

const SupplierView = () => {
  return (
    <>
      <div className="d-flex flex-column flex-root login_section">
        <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="d-flex flex-center flex-column flex-column-fluid p-10" style={{height:"100vh"}}>
            <Link to="/" className="mb-10"></Link>
            <Link to="/" className="brandName mb-12 Dropship">
              Dropship
            </Link>
            <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto ">
              <form
                className="form w-100"
                noValidate="novalidate"
                id="kt_sign_in_form"
                action="products.php"
                method="post"
              >
                <div className="text-center mb-10">
                  <h1 className="text-dark mb-3 fw-semibold">Sign In</h1>
                </div>
                <div className="fv-row mb-10">
                  <label className="form-label fs-6 fw-bold text-dark">
                    Email
                  </label>
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    name="email"
                    autoComplete="off"
                  />
                </div>
                <div className="fv-row mb-10">
                  <div className="d-flex flex-stack mb-2">
                    <label className="form-label fw-bold text-dark fs-6 mb-0">
                      Password
                    </label>
                  </div>
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="password"
                    name="password"
                    autoComplete="on"
                  />
                </div>
                <div className="text-center">
                <Link to="/supplliersetting">
                  <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-lg btn-primary w-100 mb-4"
                  >
                    <span className="indicator-label" >
                     Continue
                    </span>
                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SupplierView