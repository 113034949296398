import React from "react";
import SupplierView from "./SupplierView";

const Supplier = () => {
  return (
    <>
    <SupplierView />
    </>
  );
};

export default Supplier;
