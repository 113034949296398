import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./Supplier/css/custom.css"
// import "./Supplier/css/responsive.css"
// import "./Supplier/css/style.bundle.css"

// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@shopify/polaris/build/esm/styles.css";
import { Provider } from "react-redux";
import { store } from "./redux/helpers";
import { App } from "./App";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </Provider>,
  rootElement
);
