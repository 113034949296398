import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "react-bootstrap"; // Assuming you're using Bootstrap for collapsing elements

const SideBar = (props) => {
  const location = useLocation();

  var get_pathname = location.pathname;
  const [OpenLanguage, setOpenLanguage] = useState(false);
  const [OpenCurrence, setOpenCurrence] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedLanguage, setselectedLanguage] = useState("");

  const toggleSubMenuCurr = () => {
    setOpenCurrence(!OpenCurrence);
  };

  const toggleSubMenu = () => {
    setOpenLanguage(!OpenLanguage);
  };
  const handleCheckboxClick = (value) => {
    setSelectedCurrency(value);
  };
  const handleCheckboxClickL =(value)=>{
    setselectedLanguage(value)
  }
  // const handleCheckboxClick = (event) => {
  //   const checkbox = event.currentTarget.querySelector('input[type="checkbox"]');
  //   checkbox.checked = !checkbox.checked;
  // };

  return (
    <>
      <div
        id="kt_aside"
        class="aside"
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_toggle"
      >
        <div class="asideFirstButton btn btn-lg btn-primary rounded-2">
          <div class="menu-item">
            <a class="" href="#">
              <span class="menu-title menuSidebar">Stores</span>
              <span class="menu-arrow">
                {/* <i class="las la-caret-right"></i> */}
              </span>
            </a>
          </div>
        </div>
        <div
          class="aside-menu bg-white  mt-7 pe-0 ps-0 rounded-2 Side"
          id="kt_aside_menu"
        >
          <div
            class="w-100 mb-5 leftBar"
            id="kt_aside_menu_wrapper"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_footer, #kt_header"
            data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu, #kt_aside_menu_wrapper"
            data-kt-scroll-offset="102"
          >
            <div
              class="menu menu-column menu-rounded menu-sub-indention menu-active-bg fw-semibold my-auto"
              id="#kt_aside_menu"
              data-kt-menu="true"
            >
              {/* <div class="menu-item pt-5">
                <div class="menu-content">
                  <span class="menu-heading fw-bold text-uppercase fs-7">
                    Forecasting
                  </span>
                </div>
              </div> */}
              <div className="menu-item">
                <div class="menu-item here show menu-accordion">
                  <NavLink to="/supplliersetting">
                    <a class="menu-link ColorAdd">
                      <span class="menu-icon" id="dddddd">
                        <span class="svg-icon svg-icon-5">
                          <i className="fa-solid fa-table-list"></i>
                        </span>
                      </span>

                      <span class="menu-title menuSidebar">
                        Suppllier Settings{" "}
                      </span>
                    </a>
                  </NavLink>
                </div>

                <div class="menu-item here show menu-accordion">
                  <NavLink to="/configureFeedSource">
                    <a class="menu-link ColorAdd">
                      <span class="menu-icon">
                        <span class="svg-icon svg-icon-5">
                          <i className="fas fa-lightbulb"></i>
                        </span>
                      </span>

                      <span class="menu-title menuSidebar">Configure </span>
                      {/* Configure Feed Source */}
                    </a>
                  </NavLink>
                </div>
              </div>
              <hr class="bg-gray-400 my-2" />
              <div class="menu-item pt-2">
                <div class="menu-content">
                  <span class="menu-heading fw-bold text-uppercase fs-7">
                    Products
                  </span>
                </div>
              </div>

              <div class="menu-item here show menu-accordion">
                <NavLink to="/products">
                  <a class="menu-link ColorAdd">
                    <span class="menu-icon">
                      <span class="svg-icon svg-icon-5">
                        <i class="lar la-file-alt fs-1"></i>
                      </span>
                    </span>

                    <span class="menu-title menuSidebar">
                      Products Listing{" "}
                    </span>
                    <span class="menu-arrow"></span>
                  </a>
                </NavLink>

                {/* <div class="menu-sub menu-sub-accordion">
                  <div class="menu-item menu-link ColorAdd">
                    <span class="menu-bullet">
                      <i class="las la-angle-double-right"></i>
                    </span>
                    <NavLink 
                    // to="/languageand_currency" 
                    to={{
                      pathname:'/languageand_currency',
                      state: {title:'AllLanguage'}  
                    }}
                    >
                    <span class="menu-title menuSidebar">Language & Currency</span>
                    </NavLink>
                  </div>
                </div> */}

                <div className="menu-sub menu-sub-accordion">
                
                <NavLink to="/allanguage">
                  <div
                    className="menu-item menu-link ColorAdd"
                  >
                    <span className="menu-bullet">
                      <i class="las la-angle-double-right"></i>
                    </span>
                    <span className="menu-title menuSidebar">Language</span>
                  </div>
                  </NavLink>
                </div>

                <div className="menu-sub menu-sub-accordion">
                <NavLink to="/allcurrency">
                  <div
                    className="menu-item menu-link ColorAdd">
                    <span className="menu-bullet">
                      <i className="las la-angle-double-right"></i>
                    </span>
                    <span className="menu-title menuSidebar">Currency</span>
                  </div>
                  </NavLink>
                </div>
              </div>
             


              <hr class="bg-gray-400 my-2" />
              <div class="menu-item here show menu-accordion">
                <NavLink to="/storesListing">
                  <a class="menu-link ColorAdd">
                    <span class="menu-icon">
                      <span class="svg-icon svg-icon-5">
                        <i class="fa-solid fa-store"></i>
                      </span>
                    </span>

                    <span class="menu-title menuSidebar">Store </span>
                    <span class="menu-arrow"></span>
                  </a>
                </NavLink>

                <div class="menu-sub menu-sub-accordion">
                  <NavLink to="/Orderdetail">
                    <div class="menu-item menu-link ColorAdd">
                      <span class="menu-bullet">
                        <i class="las la-angle-double-right"></i>
                      </span>

                      <span class="menu-title menuSidebar">Order Detail</span>
                    </div>
                  </NavLink>
                </div>
                {/* <div class="menu-sub menu-sub-accordion">
                  <NavLink to="/Orderdetail">
                    <div class="menu-item menu-link ColorAdd">
                      <span class="menu-bullet">
                        <i class="las la-angle-double-right"></i>
                      </span>

                      <span class="menu-title menuSidebar">Order Detail</span>
                    </div>
                  </NavLink>
                </div> */}
              </div>
              {/* <hr class="bg-gray-400 my-2" /> */}
              {/* <div class="menu-item">
                <a class="menu-link ColorAdd">
                  <span class="menu-icon">
                    <span class="svg-icon svg-icon-5">
                      <i class="las la-sync fs-1"></i>
                    </span>
                  </span>
                  <span class="menu-title menuSidebar">Transfer Orders</span>
                </a>
              </div> */}
              {/* <hr class="bg-gray-400 my-2" /> */}
              {/* <div class="menu-item">
                <a class="menu-link ColorAdd">
                  <span class="menu-icon">
                    <span class="svg-icon svg-icon-5">
                      <i class="las la-warehouse fs-1"></i>
                    </span>
                  </span>
                  <span class="menu-title menuSidebar">Warehouses</span>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
