import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SideBar from "../SideBar";
import { Link } from "react-router-dom";
import Switch from "react-switch";

const AllCurrency = () => {
  const [currencies, setCurrencies] = useState([
    { id: 1, symbol: "$", name: "US Dollar", checked: true },
    { id: 2, symbol: "£", name: "UK Pound", checked: false },
    { id: 3, symbol: "₹", name: "Indian Rupee", checked: false },
    { id: 4, symbol: "₩", name: "Korean Won", checked: false },
    // Add more currencies as needed
  ]);

  const handleChange = (id) => {
    setCurrencies((prevCurrencies) =>
      prevCurrencies.map((currency) =>
        currency.id === id
          ? { ...currency, checked: true }
          : { ...currency, checked: false }
      )
    );
  };

  return (
    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        <div className="container">
          <div className="row">
            <div className="col-2">
              <SideBar />
            </div>
            <div className="col-10">
              <div
                className="wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
              >
                <Header />
                <div
                  class="content d-flex flex-column flex-column-fluid ps-0"
                  id="kt_content"
                >
                  <div class="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" class="container-fluid">
                      <div class="card border">
                        <div class="card-header border-bottom py-4 px-6">
                          <div class="card-title">
                            <div class="customSearchBox me-3">
                              <div class="input-group position-relative">
                                <button
                                  type="submit"
                                  class="btn btn-icon btn-primary customSearchBtn"
                                >
                                  <i class="las la-search fs-1"></i>
                                </button>
                                <input
                                  type="text"
                                  class="form-control border"
                                  aria-label="Text input with dropdown button"
                                  placeholder="Search"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-header border-bottom py-4 px-6">
                          <div class="col-sm-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
                            <h3 class="text-dark fw-bolder my-1 p-4">
                              {" "}
                              All Currency
                            </h3>
                          </div>
                        </div>

                        <div class="card-body p-0 table-responsive">
                          <table
                            class="table table-striped align-middle table-row-solid fs-6 gs-7 gy-5 productsMainC stripe row-border order-column"
                            id="productsMain"
                          >
                            <thead>
                              <tr class="text-start text-dark fw-bold fs-6 gs-0">
                                <th class="w-10px pe-2"></th>
                                <th>sr.No</th>
                                <th class="min-w-125px">Currency Symbol</th>
                                <th class="min-w-125px">Currency Name</th>
                                <th class="min-w-125px">Enable/Disable</th>
                              </tr>
                            </thead>
                            <tbody class="text-gray-600 fw-semibold">
                              {currencies.map((currency) => (
                                <tr key={currency.id}>
                                  <td></td>
                                  <td>{currency.id}</td>
                                  <td>{currency.symbol}</td>
                                  <td>{currency.name}</td>
                                  <td>
                                    <Switch
                                      onChange={() => handleChange(currency.id)}
                                      checked={currency.checked}
                                      onColor="#f08303"
                                      uncheckedIcon={false}
                                   
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div class="card-footer">
                          <div class="tablePaginationMain">
                            <p class="m-0">Showing 1 to 10 of 30 records</p>
                            <div class="tablePagination">
                              <nav aria-label="...">
                                <ul class="pagination">
                                  <li class="page-item disabled">
                                    <a class="page-link">Previous</a>
                                  </li>
                                  <li class="page-item">
                                    <a class="page-link" href="#">
                                      1
                                    </a>
                                  </li>
                                  <li
                                    class="page-item active"
                                    aria-current="page"
                                  >
                                    <a class="page-link" href="#">
                                      2
                                    </a>
                                  </li>
                                  <li class="page-item">
                                    <a class="page-link" href="#">
                                      3
                                    </a>
                                  </li>
                                  <li class="page-item">
                                    <a class="page-link" href="#">
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllCurrency;
