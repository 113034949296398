import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SideBar from '../SideBar';
import {Link} from 'react-router-dom';


const ViewProducts = () => {
  return (
    <div className="d-flex flex-column flex-root">
    <div className="page d-flex flex-row flex-column-fluid">
      <div className="container">
        <div className="row">
          <div className="col-2"><SideBar /></div>
          <div className="col-10">
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <Header />
              <div
                className="content d-flex flex-column flex-column-fluid ps-0 "
                id="kt_content"
              >
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div
                    id="kt_content_container"
                    className="container-fluid "
                  >
                    <Link to="/storesListing">Back</Link>
                    <h1>View Products</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  )
}

export default ViewProducts