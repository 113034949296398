import React from 'react';
import Leftsidebar from "../component/leftsidebar";
import Header from "../component/header";
import Footer from "../component/footer";
import Foot from "../component/foot";
import Loader from "../Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import shopicon from "../assets/crImages/Vector.png";
import top_met from "../assets/crImages/BG_ImageTopLeft.png";
import right_met from "../assets/crImages/BG_ImageTopRight.png";
import bottm_met from "../assets/crImages/BG_ImageBottom.png";


class Connection extends React.Component {

    constructor(props) {
        super(props);
        this.state = { shopname: "", appToken: "", apiKey: "", c_data: null, loader: false, platform: "shopify", languages: [], language: "", adminUrl: "" };
        this.connectionList()
        this.language();
    }


    connectionList = async () => {
        try {
            var conn_ = await sessionStorage.getItem('connection');
            if (conn_ != "{}") {
                var c_ = JSON.parse(conn_);
                this.setState({ c_data: c_ })
            }
        } catch (error) {

        }
    }



    connection = async (e) => {
           e.preventDefault()
        try {
            var data;
            var headers_;
            if (this.state.shopname == "") {
                return { msg: "Shopname required" }
            }
            if (this.state.appToken == "") {
                return { msg: "Token required" }
            }
            e.preventDefault();
            this.setState({ loader: true });
            var token_ = sessionStorage.getItem("token");
            if (token_) {
                headers_ = {
                    'authorization': token_
                }
            } else {
                headers_ = {
                    'authorization': ""
                }
            }
            data = { shopname: this.state.shopname, appToken: this.state.appToken }
            console.log(data,'datadata')
            await axios.post(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/testconnection`, data, {
                headers: headers_
            })
                .then((res) => {
                    if (res.data.msg.shop) {
                        try {
                            toast.success("connection sucessfully created");
                            this.setState({ loader: false });
                        } catch (error) {
                            return ({ message: error })
                        }
                    } else {
                        toast.error(res.data.msg.message)
                        this.setState({ loader: false });

                    }
                }).catch((err) => {
                    toast.error("something happened")
                    this.setState({ loader: false });
                })

        } catch (error) {
            // console.log("connection error", error)
            return error
        }
    }

    async savetoplatform(data, headers_){
        // await axios.post(`${process.env.REACT_APP_API_SUPPLIER}/api/base/supplier/workflow`, data, {
        //     headers: headers_
        // })
        // .then(async (platformw) => {
        // })
        await axios.post(`${process.env.REACT_APP_API_platformX}/api/platform/workflow`, data, {
            headers: headers_
        })
        .then(async (platformw) => {
        })
    }

    submit = async (e) => {
        try {
            e.preventDefault();
            var data;
            var headers_;
            if (this.state.shopname == "") {
                return { msg: "Shopname required" }
            }
            if (this.state.appToken == "") {
                return { msg: "Token required" }
            }
            this.setState({ loader: true });
            delete this.state["loader"];
            if(this.state.shopname.includes('myshopify.com')){
                sessionStorage.setItem('platform', 'shopify')
            }else{
                sessionStorage.setItem('platform', '')
            }
            var token_ = sessionStorage.getItem("token");
            var ref_token_ = sessionStorage.getItem("refresh_token");
            var user_ = sessionStorage.getItem("user");
            if (token_ && ref_token_ && user_) {
                headers_ = {
                    'authorization': token_
                }
                this.state.accessToken = token_;
                this.state.refresh_Token = ref_token_;
                this.state.user = user_;
            } else {
                headers_ = {
                    'authorization': ""
                }
                this.state.accessToken = "";
                this.state.refresh_Token = "";
                this.state.user = {};
            }
            this.state.adminUrl = process.env.REACT_APP_API_platformX
            data = this.state;
            var shopData = {
                store_url: this.state.shopname,
                api_secret: this.state.appToken
            }
            await axios.post(`${process.env.REACT_APP_API_platformX}/api/check/connection`, { "shop": this.state.shopname, "tok": this.state.appToken }, {
                headers: headers_
            })
            .then(async (conn) => {
                if(conn.data && conn.data.shop && conn.data.shop.id){
                    data.is_conn = 1
                }
                else{
                    data.is_conn = 0
                }
                await axios.post(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/saveconnection`, data, {
                    headers: headers_
                })
                .then(async (res) => {
                    if (res.data.msg.id) {
                        this.savetoplatform(data, headers_)
                        try {
                            toast.success(res.data.msg.message);
                            this.setState({ loader: false });
                            sessionStorage.setItem('connection', JSON.stringify(shopData))
                            document.getElementById("connectionForm").reset()
                            this.props.history.push('/language');
                        } catch (error) {
                            return ({ message: error })
                        }
                    } else {
                        this.savetoplatform(data, headers_)
                        toast.error(res.data.msg.message)
                        this.setState({ loader: false });

                    }
                }).catch((err) => {
                    toast.error("something happened")
                    this.setState({ loader: false });
                })

            }).catch((err) => {
                toast.error("something happened")
                this.setState({ loader: false });
            })

        } catch (error) {
            console.log("save configuration error", error)
        }
    }

    ChangeToken(token) {
        try {
            var length = token.length;
            var h_length = Math.floor(length / 2);

            var t_ = token.substring(0, h_length);
            var star = '**********';
            var v_ = t_.concat(star)
            if (v_) {
                return v_
            }
        } catch (error) {
            return ""
        }
    }

    deleteConn = async (e) => {
        try {
            var data;
            var headers_;
            e.preventDefault();
            this.setState({ loader: true });
            var token_ = sessionStorage.getItem("token");
            var user_ = sessionStorage.getItem("user");
            var conn_ = sessionStorage.getItem("connection");
            
            if (token_) {
                headers_ = {
                    'authorization': token_
                }
            } else {
                headers_ = {
                    'authorization': ""
                }
            }
            data = { user: user_, conn: conn_ }
            console.log(data, headers_)
            await axios.post(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/disconnect`, data, {
                headers: headers_
            })
                .then((res) => {
                    if (res.data.msg) {
                        try {
                            this.setState({ loader: false });
                            sessionStorage.removeItem('connection')
                            window.location.reload(true);

                        } catch (error) {
                            return ({ message: error })
                        }
                    } else {
                        toast.error(res.data.msg.message)
                        this.setState({ loader: false });

                    }
                }).catch((err) => {
                    toast.error("something happened")
                    this.setState({ loader: false });
                })
        } catch (error) {

        }
    }

    language = async () => {
        try {
            var token_ = sessionStorage.getItem("token");
            this.setState({ loader: true })
            // console.log(token_)
            await axios.get(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/language`, {
                headers: {
                    'authorization': (token_ ? token_ : "")
                }
            }).then((res) => {
                if (res.data.data.length != 0) {
                    this.setState({ loader: false, languages: res.data.data })
                } else {
                    this.setState({ loader: false, languages: [] })
                }
            }).catch((error) => {
                this.setState({ loader: false })
                return ({ message: error })
            })
        } catch (error) {

        }
    }

    render() {
        return <>

            {this.state.c_data == null ?

                <div className="d-flex flex-column flex-root  new_meyt">
                    <div className="page d-flex flex-row flex-column-fluid">
                        {/* <Leftsidebar /> */}
                        <div
                            className=" d-flex flex-column flex-row-fluid"
                            id="kt_wrapper"
                        >
                            <Header />

                            <div
                                className="content d-flex flex-column flex-column-fluid ps-0"
                                id="kt_content"
                            >
                                <div className='head_met'>
                                    <h1>
                                        Dashboard
                                    </h1>
                                    <p>
                                        Welcome To The StrawberryNET
                                    </p>
                                </div>
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid confi">
                                        <div className="card border">
                                            <div className="card-header ">
                                                <div className="col-sm-12 col-md-12">
                                                    <div className='shop_icon'>
                                                        <img src={shopicon} />
                                                    </div>
                                                    <div className='met_in_hed'>
                                                        <h3 className="text-dark fw-bold my-1">Connect Store</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <form id="connectionForm">

                                                    <div className="form-group row feedForm1 justify-content-center">

                                                        <div className="col-sm-12">
                                                            <input type="text" className="form-control" id="inputShopname" placeholder="test-store.myshopify.com" onChange={e => this.setState({ shopname: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row feedForm justify-content-center">

                                                        <div className="col-sm-12">
                                                            <input type="text" className="form-control" id="inputToken" placeholder="Enter App Token" onChange={e => this.setState({ appToken: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row feedForm justify-content-center">

                                                        <div className="col-sm-12">
                                                            <input type="text" className="form-control" id="inputapiKey" placeholder="Enter Secret Key" onChange={e => this.setState({ apiKey: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div style={{ padding: "23px 0 0 6px", marginLeft: 'auto', display: 'flex', justifyContent: 'start' }}>
                                                        <button
                                                            type="submit"
                                                            id="kt_sign_in_submit"
                                                            className="btn-connection mb-5"
                                                            onClick={this.connection}
                                                            style={{ marginLeft: "20px", background: "#D92E3A", color: "#fff" }}
                                                        >
                                                            <span className="indicator-label">TEST CONNECTION</span>

                                                        </button>

                                                        <button
                                                            type="submit"
                                                            id="kt_sign_in_submit"
                                                            className="btn-connection mb-5"
                                                            onClick={this.submit} style={{ marginLeft: "20px", background: "#7C4391", color: "#fff" }}

                                                        >
                                                            <span className="indicator-label">SAVE</span>

                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                            <div className='top_met'>
                                                <img src={top_met} />
                                            </div>
                                            <div className='right_met'>
                                                <img src={right_met} />
                                            </div>
                                            <div className='bottom_met'>
                                                <img src={bottm_met} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer />
                        {this.state.loader == true ? <Loader /> : ''}
                    </div>
                    <Footer />
                </div>
                :
                <div className="d-flex flex-column flex-root">

                    <div className="page d-flex flex-row flex-column-fluid">
                        <div className='container'>
                            <div className='row'>
                                <div className='col-2'>
                                    <Leftsidebar />
                                </div>
                                <div className='col-10'>
                                    <div
                                        className="wrapper d-flex flex-column flex-row-fluid"
                                        id="kt_wrapper"
                                    >
                                        <Header />
                                        <div
                                            className="content d-flex flex-column flex-column-fluid ps-0 "
                                            id="kt_content"
                                        >
                                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                                <div id="kt_content_container" className="container-fluid ">
                                                    <div className="card border b-div">
                                                        <div className="card-header">
                                                            <div className="col-sm-10 col-md-10">
                                                                <h3 className="text-dark fw-bold my-1">Connect Store</h3>
                                                            </div>
                                                            <div className="col-sm-2 col-md-2" >
                                                                <button className="disconnect" onClick={this.deleteConn}>Disconnect</button>
                                                            </div>
                                                        </div>
                                                        <div className="rev-section">
                                                            <div className="row rev-sec1">
                                                                <div className="col-2">
                                                                    <h3>Connection</h3>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className='textWithIcon'>
                                                                        <div className="info-icon-rev1">
                                                                            <svg className="svg-inline--fa fa-circle-check" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                                                                        </div>
                                                                        <h3>{this.state.c_data.store_url}</h3>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className='textWithIcon'>
                                                                        <div className="info-icon-rev1">
                                                                            <svg className="svg-inline--fa fa-circle-check" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                                                                        </div>
                                                                        <h3>{this.ChangeToken(this.state.c_data.api_secret)}</h3>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2">
                                                                    <div className='textWithIcon' style={(this.state.c_data && this.state.c_data.language ? {display: 'flex'} : {display: 'none'})}>
                                                                        <div className="info-icon-rev1">
                                                                            <svg className="svg-inline--fa fa-circle-check" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
                                                                        </div>
                                                                        <h3>{(this.state.c_data && this.state.c_data.language ? this.state.c_data.language : "")}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {this.state.loader == true ? <Loader /> : ''}
                        </div>
                    </div>
                    <Footer />
                </div>
            }
            <Foot />
        </>
    }
}

export default Connection;
