import React from 'react';
import { Link, withRouter } from "react-router-dom";
import '../index.css';
import axios from "axios";
import Loader from "../Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../assets/crImages/Logo.png";

import { connect } from 'react-redux';
import { userActions } from '../redux/actions';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: "", password: "", loader: false, formErrors: { static: null } };
        this.initialState = this.state;

        // reset login status
        // this.props.logout();

        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    handleFormValidation() {
        const { email, password } = this.state;
        let formErrors = {};
        let formIsValid = true;
        //Email    
        if (!email) {
            formIsValid = false;
            formErrors["emailErr"] = "email is required.";
        } else if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))) {

            formIsValid = false;
            formErrors["emailErr"] = "Invalid email id.";
        } else {
            formIsValid = true;
        }

        this.setState(
            {
                formErrors: formErrors
            }
        );
        return formIsValid;
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    submit = async (e) => {
        e.preventDefault();
    //   console.log(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/login`)
        if (this.handleFormValidation()) {
            this.setState({ loader: true });
            try {
                var data = { email: this.state.email, password: this.state.password };
                await axios.post(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/login`, data)
                    .then((res) => {
                        if (res.data.msg.token) {
                            console.log(res.data,'res.datares.data')
                            try {
                                sessionStorage.setItem("token", res.data.msg.token);
                                sessionStorage.setItem("refresh_token", res.data.msg.refresh_token);
                                sessionStorage.setItem("user", JSON.stringify(res.data.msg.data));
                                var s_ = (res.data.msg.shopData.length != 0 ? res.data.msg.shopData[0].connection : {});
                                if (res.data.msg.shopData.length == 0) {
                                    s__ = {}
                                } else {
                                    var s__ = {
                                        store_url: s_.store_url,
                                        api_secret: s_.api_secret,
                                        language: res.data.msg.shopData[0].language
                                    }
                                }
                                if(s__.store_url){

                                    sessionStorage.setItem("connection", JSON.stringify(s__))
                                    if (sessionStorage.getItem("Store")) {
                                        sessionStorage.removeItem("Store");
                                    }
                                }
                                    toast.success(res.data.msg.message)
                                    this.setState({ loader: false });
                                    this.props.history.push('/');

                                    window.history.pushState(null, null, window.location.href);
                                    window.onpopstate = function (event) {
                                        window.history.go(1);
                                    };
                            } catch (error) {
                                return ({ message: error })
                            }
                        } else {

                            toast.error(res.data.msg)
                            this.setState({ loader: false });

                        }
                    }).catch((err) => { console.log('error', err) })

            } catch (error) {
                alert("Login Failed")
            }
        }

    }

    render() {
        return <>
            <div className="d-flex flex-column flex-root login_section">
                <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                    <div className='logo_met_in'>
                        <Link to="/connection" className="brandName" style={{ color: "#ff8c00" }}>
                            <img src={logo} />
                        </Link>
                    </div>
                    <div className="d-flex flex-center flex-column flex-column-fluid p-10">
                        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                            <form
                                className="form w-100"
                                noValidate="novalidate"
                                id="kt_sign_in_form"
                                action="products.php"
                                method="post"
                            >
                                <div className="text-center mb-10">
                                    <h1 className="text-dark mb-3 fw-semibold">Sign In</h1>
                                    {/* <h2>If not a user, Click here to <Link to="/signup" className="mb-10">Signup</Link></h2> */}
                                </div>
                                <div className="fv-row mb-10">
                                    <label className="form-label fs-6 fw-bold text-dark">
                                        Email
                                    </label>
                                    <input
                                        className="form-control form-control-lg form-control-solid"
                                        type="text"
                                        name="email"
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                    />{this.state.formErrors.emailErr ?
                                        <> <div style={{ marginTop: "5px", color: "red" }}>{this.state.formErrors.emailErr}</div>
                                        </> : <></>}
                                </div>
                                <div className="fv-row mb-10">
                                    <div className="d-flex flex-stack mb-2">
                                        <label className="form-label fw-bold text-dark fs-6 mb-0">
                                            Password
                                        </label>
                                        {/* <a href="#" className="link-primary fs-6 fw-bold">
                                        Forgot Password ?
                                    </a> */}
                                    </div>
                                    <input
                                        className="form-control form-control-lg form-control-solid"
                                        type="password"
                                        name="password"
                                        onChange={this.handleChange}
                                        autoComplete="on"
                                    />
                                </div>
                                <div className="text-center">
                                    <button
                                        type="button"
                                        id="kt_sign_in_submit"
                                        className="btn btn-lg btn-primary w-100 mb-5" onClick={this.submit}
                                        style={{ backgroundColor: '#D92E3A' }}
                                    >

                                        <span className="indicator-label">Continue</span>
                                        <span className="indicator-progress">
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer />
                {this.state.loader == true ? <Loader /> : ''}
            </div>
        </>
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };