import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Connection from "./pages/connection.js";
import Configure from "./pages/configure";
import { LoginPage } from "./pages/login.js";
import Signup from "./pages/signup.js";
import Language from "./pages/language.js";
import Order from "./pages/order.js";
import { connect } from "react-redux";
import { history } from "./redux/helpers";
import { alertActions } from "./redux/actions";
import { PrivateRoute } from "./redux/components";
import Supplier from "./Supplier/Supplier.js";
import { StoresListing } from "./Supplier/StoreListingMain.js/StoresListing";
import ConfigureFeedSource from "./Supplier/ConfigureFeedSource";
import DashBoard from "./Supplier/DashBoard";
import Products from "./Supplier/Products/Products";
import StoreConfiguration from "./Supplier/StoreListingMain.js/StoreConfiguration";
import ViewOrder from "./Supplier/StoreListingMain.js/ViewOrder";
import ViewProducts from "./Supplier/StoreListingMain.js/ViewProducts";
import  LanguageandCurrency  from "./Supplier/Products/LanguageandCurrency";
import SuppllierSetting from "./Supplier/Products/SuppllierSetting";
import OrderList from "./Supplier/StoreListingMain.js/OrderList.js";
import OrderDetail from "./Supplier/StoreListingMain.js/OrderDetail.js";
import AllLanguage from "./Supplier/Products/AllLanguage.js";
import AllCurrency from "./Supplier/Products/AllCurrency.js";
import EditAddresss from "./Supplier/StoreListingMain.js/EditAddresss.js";
class App extends React.Component {
  constructor(props) {
    super(props);
    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });
  }

  render() {
    const { alert } = this.props;
    return (
      // <Router>
      //   <Route exact path="/" component={Login} />
      //   <Route exact path="/signup" component={Signup} />
      //   <Route exact path="/connection" component={Connection} />
      //   <Route path='/configure' component={Configure} />
      // </Router>
      <>
        {alert.message && (
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        )}
        <Router history={history}>
          <Switch>
            <PrivateRoute exact path="/" component={Connection} />
            {/* <Route path="/login" component={LoginPage} /> */}
            {/* Supplier Login */}
            <Route path="/login" component={Supplier} />
             {/* Supplier Login */}
            <Route path="/signup" component={Signup} />
            <Route path="/language" component={Language} />
            <Route path="/configure" component={Configure} />

            {/* ///  Supplier*/}
            <Route element={<DashBoard />}>
              <Route path="/storesListing" component={StoresListing} />
              <Route
                path="/configureFeedSource"
                component={ConfigureFeedSource}
              />
              <Route path="/products" component={Products} />
              <Route
                path="/storeconfiguration"
                component={StoreConfiguration}
              />
              <Route path="/vieworder" component={ViewOrder} />
              <Route path="/ViewProducts" component={ViewProducts} />
              <Route path="/supplliersetting" component={SuppllierSetting} />
              <Route path="/orderlist" component={OrderList} />
              <Route path="/Orderdetail" component={OrderDetail} />
              <Route path="/allanguage" component={AllLanguage} />
              <Route path="/allcurrency" component={AllCurrency} />
              <Route path="/editaddresss" component={EditAddresss} />
             <Route
              path="/languageand_currency"
              component={(props) => (
                <LanguageandCurrency {...props} type="Suppliers" />
              )}
            />
          </Route>
            {/* </DashBoard> */}
            {/* ///  Supplier*/}
            <Redirect from="*" to="/" />
          </Switch>
        </Router>
      </>
    );
  }
}
// export default App;
function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
