// import React from 'react'
// import Language from '../../pages/language'

// export const LanguageandCurrency = ({type}) => {
   
import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SideBar from "../SideBar";
import { withRouter } from "react-router-dom";
import axios from "axios";
// import Loader from "../";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class LanguageandCurrency extends React.Component {
    constructor(props) {
        super(props);
        this.language();
        this.currency();
        this.selectedLanguage();
         
        this.state = {
            language: true, currency: false, loader: false, languages: [], currencies: []
        }
        $(document).ready(function () {
            $(".js-example-basic-lang, .js-example-basic-curr").select2();
        });

    }

    changeMode(event) {
        if (event.target.value == "language") {
            this.setState({ language: true, currency: false })
        }
        if (event.target.value == "currency") {
            this.setState({ language: false, currency: true })
        }
    }

    submit = async (e) => {
        try {
            var data;
            e.preventDefault();

            var lang_ = [];
            var curr_ = [];
            var languagesall = $('.js-example-basic-lang').find(':selected');
            for (var i = 0; i < languagesall.length; i++) {
                var arr = languagesall[i].value.split(",");
                var l = arr.splice(0, 1).join("");
                var lc = arr.join(",");
                var obj = {
                    lang: l,
                    code: lc
                }
                lang_.push(obj)
            }
            var currencyall = $('.js-example-basic-curr').find(':selected');
            for (var i = 0; i < currencyall.length; i++) {
                var arr = currencyall[i].value.split(",");
                var c = arr.splice(0, 1).join("");
                var cc = arr.join(",");
                var obj = {
                    curr: c,
                    code: cc
                }
                curr_.push(obj)
            }
            this.setState({ loader: true });
            var token_ = sessionStorage.getItem("token");
            var user_ = sessionStorage.getItem("user");

            if (lang_.length == 0 || curr_.length == 0) {
                toast.error((lang_.length == 0 && curr_.length == 0 ? "language and currency empty" : (curr_.length == 0 ? "currency empty" : "language empty")))
                this.setState({ loader: false });
                return ({ message: (lang_.length == 0 && curr_.length == 0 ? "language and currency empty" : (curr_.length == 0 ? "currency empty" : "language empty")) })
            }

            var data = {
                language: (lang_.length != 0 ? lang_ : []),
                currency: (curr_.length != 0 ? curr_ : []),
                user: user_
            }

            await axios.post(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/savelanguage`, data, {
                headers: {
                    'authorization': (token_ ? token_ : "")
                }
            })
                .then((res) => {
                    if (res.data.msg.id) {
                        try {
                            var con_ = sessionStorage.getItem('connection');
                            if (con_) {
                                var c_ = JSON.parse(con_);
                                c_.language = (lang_.length != 0 ? lang_[0].lang : '');
                                sessionStorage.setItem('connection', JSON.stringify(c_))
                            }
                            toast.success(res.data.msg.message);
                            this.setState({ loader: false });
                            if (curr_.length != 0) {
                                this.props.history.push('/configure');
                            } else {
                                this.setState({ currency: true, language: false });
                            }

                        } catch (error) {
                            return ({ message: error })
                        }
                    } else {
                        toast.error(res.data.msg.message)
                        this.setState({ loader: false });

                    }
                }).catch((err) => {
                    toast.error("something happened")
                    this.setState({ loader: false });
                })

        } catch (error) {
            return ({ message: error })
        }
    }

    selectedLanguage = async () => {
        try {
            var token_ = sessionStorage.getItem("token");
            this.setState({ loader: true })
            var con_ = sessionStorage.getItem('connection');
            if (con_) {
                var c_ = JSON.parse(con_);
            await axios.get(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/workflow/store/${c_.store_url}`, {
                headers: {
                    'authorization': (token_ ? token_ : "")
                }
            }).then((res) => {
                var sl_ = [];
                var sc_ = [];
                if (res.data.data.length != 0) {
                    this.setState({ loader: false, l_list: res.data.data[0].workflow.languages, c_list: res.data.data[0].workflow.currencies });
                    for(let i=0; i< res.data.data[0].workflow.languages.length; i++){
                        var str = res.data.data[0].workflow.languages[i].lang+","+res.data.data[0].workflow.languages[i].code;
                        sl_.push(str)
                    }
                    $('.js-example-basic-lang').val(sl_);
                    $('.js-example-basic-lang').trigger('change');
                    for(let i=0; i< res.data.data[0].workflow.currencies.length; i++){
                        var str = res.data.data[0].workflow.currencies[i].curr+","+res.data.data[0].workflow.currencies[i].code;
                        sc_.push(str)
                    }
                    $('.js-example-basic-curr').val(sc_);
                    $('.js-example-basic-curr').trigger('change');
                    
                } else {
                    this.setState({ loader: false, l_list: [] })
                }
            }).catch((error) => {
                this.setState({ loader: false })
                return ({ message: error })
            })
        }
        } catch (error) {

        }
    }

    language = async () => {
        try {
            var token_ = sessionStorage.getItem("token");
            this.setState({ loader: true })
            await axios.get(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/language`, {
                headers: {
                    'authorization': (token_ ? token_ : "")
                }
            }).then((res) => {
                if (res.data.data.length != 0) {
                    this.setState({ loader: false, languages: res.data.data })
                } else {
                    this.setState({ loader: false, languages: [] })
                }
            }).catch((error) => {
                this.setState({ loader: false })
                return ({ message: error })
            })
        } catch (error) {

        }
    }

    currency = async () => {
        try {
            var token_ = sessionStorage.getItem("token");
            this.setState({ loader: true })
            await axios.get(`${process.env.REACT_APP_API_SUPPLIER}/api/base/admin/currency`, {
                headers: {
                    'authorization': (token_ ? token_ : "")
                }
            }).then((res) => {
                if (res.data.data.length != 0) {
                    this.setState({ loader: false, currencies: res.data.data })
                } else {
                    this.setState({ loader: false, currencies: [] })
                }
            }).catch((error) => {
                this.setState({ loader: false })
                return ({ message: error })
            })
        } catch (error) {

        }
    }

    render() {
      const { location } = this.props;
      // Extract the customData from the state
      // const customData = location.state.title;
      //     console.log(customData,'customDatacustomData')
        return <>
            <div className="d-flex flex-column flex-root config_set_page">
                <div className="page d-flex flex-row flex-column-fluid">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-2'>
                            <SideBar/>
                            </div>
                            <div className='col-10'>
                                <div
                                    className="wrapper d-flex flex-column flex-row-fluid"
                                    id="kt_wrapper"
                                >
                                     <Header />
                                    <div
                                        className="content d-flex flex-column flex-column-fluid ps-0 "
                                        id="kt_content"
                                    >
                                        <div className="post d-flex flex-column-fluid" id="kt_post">
                                            <div id="kt_content_container" className="container-fluid confi">
                                                <div className="card border b-div">
                                                    <div className="card-header">
                                                        <div className="col-sm-12 col-md-12 ">
                                                            <h3 className="text-dark fw-bold my-1">Select language and Currency to import products</h3>
                                                            {/* <p>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                        </p> */}
                                                        </div>
                                                    </div>
                                                    <div className="rule-section">
                                                        <div className="row sche-sec2">
                                                            <div className="col sche-sec2-p1">
                                                                <div className="row ">
                                                                    <div className="col stack">
                                                                        <div className={(this.state.language == true ? "form-check form-check-inline active MainHH" : "form-check form-check-inline")}>
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inputStock" value="language" onClick={() => this.changeMode(event)} />
                                                                            <label className="form-check-label" for="inputStock">
                                                                                <div>
                                                                                    Language
                                                                                </div>
                                                                                <i className="fas fa-language"></i>
                                                                            </label>
                                                                        </div>
                                                                        <div className={(this.state.currency == true ? "form-check form-check-inline active MainHH" : "form-check form-check-inline")}>
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inputPurchase" value="currency" onClick={() => this.changeMode(event)} />
                                                                            <label className="form-check-label" for="inputPurchase">
                                                                                <div>
                                                                                    Currency
                                                                                </div>
                                                                                {/* <i className="fa-solid fa-cart-shopping"></i> */}
                                                                                <i className="fas fa-money-check"></i>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row " style={{ display: (this.state.language == true ? "flex" : "none"), marginTop: '40px' }}>
                                                            <div className="col sche-sec2-p1">
                                                                <div className="row ">
                                                                    <div className="col">
                                                                        <div className='switch_group'>
                                                                            <div className="form-check form-switch multipleSelect" >
                                                                                <select className="form-control js-example-basic-lang" id="lang" name="language[]" >
                                                                                    {this.state.languages.length != 0 ? this.state.languages.map((element, index) => {
                                                                                        return <option key={index} value={element.languageName + ',' + element.languageId} >{element.languageName}</option>
                                                                                    }) : <option>No Language</option>}

                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="row " style={{ display: (this.state.currency == true ? "flex" : "none"), marginTop: '40px' }}>
                                                            <div className="col sche-sec2-p1">
                                                                <div className="row ">
                                                                    <div className="col">
                                                                        <div className='switch_group'>
                                                                            <div className="form-check form-switch multipleSelect">
                                                                                <select className="form-control js-example-basic-curr" id="curr" name="currency[]"  >
                                                                                    {this.state.currencies.length != 0 ? this.state.currencies.map((element, index) => {
                                                                                        return <option key={index} value={element.currency + ',' + element.curr_code} >{element.currency}</option>
                                                                                    }) : <option>No Currency</option>}

                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="rule-footer">
                                                            <div className="row sche-save">
                                                                <div className="col">
                                                                    <button type="save" id="kt_sign_in_save" style={{ background: "#f08303", color: "#fff", marginTop: '40px', padding: '13px 30px' }} className="btn-connection btn-save mb-2" onClick={this.submit}>
                                                                        <span className="indicator-label" style={{ background: "#f08303", color: "#fff" }}>SAVE</span>
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                    {/* {this.state.loader == true ? <Loader /> : ''} */}
                </div>
                <Footer />
            </div>
        </>
    }
}

export default withRouter(LanguageandCurrency);
