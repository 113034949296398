import React from 'react';
import Header from './Header/Header';
import SideBar from './SideBar';
import Footer from './Footer/Footer';
// import { Outlet } from 'react-router'; // Import from react-router
// import { Outlet } from 'react-router-dom'; // Import from react-router-dom

const DashBoard = () => {
  return (
    <div>
       <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div className="container">
            <div className="row">
              <div className="col-2"><SideBar /></div>
              <div className="col-10">
                <div
                  className="wrapper d-flex flex-column flex-row-fluid"
                  id="kt_wrapper"
                >
                  <Header />
                  {/* <Outlet></Outlet> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default DashBoard