import React from 'react'
import {
    Link, NavLink, useLocation
} from 'react-router-dom';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



const Leftsidebar = () => {

    const location = useLocation()
    var get_pathname = location.pathname;

    return (
        <>
            <div
                id="kt_aside"
                className="aside"
                data-kt-drawer="true"
                data-kt-drawer-name="aside"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_aside_toggle"
            >
                {/* <div className="asideFirstButton btn btn-lg btn-primary rounded-2" style={{ backgroundColor: "#ff8c00 " }}>
                    <div className="menu-item">
                        <Link to="#" className="menu-link">
                            <span className="menu-title"><strong>
                               Stores </strong></span>
                            <span className="menu-arrow">
                                <i className="fas fa-caret-right" />
                            </span>
                        </Link>
                    </div>
                </div> */}
                <div
                    className="aside-menu bg-white border mt-7 pe-0 ps-0 rounded-2"
                    id="kt_aside_menu"
                >
                    <div
                        className="w-100"
                        id="kt_aside_menu_wrapper"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-dependencies="#kt_aside_footer, #kt_header"
                        data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu, #kt_aside_menu_wrapper"
                        data-kt-scroll-offset={102}
                    >
                        <div
                            className="menu menu-column menu-rounded menu-sub-indention menu-active-bg my-auto"
                            id="#kt_aside_menu"
                            data-kt-menu="true" >
                            <div className="menu-item">
                                <NavLink to="/connection" className={(get_pathname === "/") ? "menu-link active" : (get_pathname === "/edit_product") ? "menu-link active" : "menu-link"}>
                                    <span className="menu-icon">
                                        <span className="font-awesome">
                                            <i className="fas fa-lightbulb"></i>
                                        </span>
                                    </span>
                                    <span className="menu-title">Connection</span>
                                </NavLink>
                            </div>
                            <div className="menu-item">
                                <NavLink to="/language" className={(get_pathname === "/language") ? "menu-link active" : "menu-link"}>
                                    <span className="menu-icon">
                                        <span className="font-awesome">
                                            <i className="fas fa-language"></i>
                                        </span>
                                    </span>
                                    <span className="menu-title">Language And Currency</span>
                                </NavLink>
                            </div>

                            {/* <div className="menu-item here show menu-accordion">
                                <NavLink to="/vandors" className={(get_pathname === "/vandor") ? "menu-link active" : (get_pathname === "/editvendor") ? "menu-link active" : (get_pathname === "/addvendor") ? "menu-link active" : "menu-link"}>
                                    <span className="menu-icon">
                                        <span className="svg-icon svg-icon-5">
                                            <i className="fas fa-user" />
                                        </span>
                                    </span>
                                    <span className="menu-title">Vendors</span>
                                    <span className='drop_down_icon'>
                                    <i className="fa-solid fa-angle-down"></i>
                                    </span>
                                </NavLink>
                                <div className="menu-sub menu-sub-accordion">
                                    <div className="menu-item">
                                        <NavLink to="/assignvariants" className="menu-link">
                                            <span className="menu-bullet">
                                                <i className="las la-angle-double-right" />
                                            </span>
                                            <span className="menu-title">Assign Variants</span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div> */}
                            {/* <hr className="bg-gray-400 my-2" /> */}
                            <div className="menu-item">
                                <NavLink to="/configure" className={(get_pathname === "/configure") ? "menu-link active" : "menu-link"}>
                                    <span className="menu-icon">
                                        <span className="svg-icon svg-icon-5">
                                            <i className="fa-solid fa-table-list" />
                                        </span>
                                    </span>
                                    <span className="menu-title">Configure</span>
                                </NavLink>
                            </div>
                            {/* <div className="menu-item">
                                <NavLink to="/order" className={(get_pathname === "/order") ? "menu-link active" : "menu-link"}>
                                    <span className="menu-icon">
                                        <span className="svg-icon svg-icon-5">
                                            <i class="fa-solid fa-list"></i>
                                        </span>
                                    </span>
                                    <span className="menu-title">Order</span>
                                </NavLink>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Leftsidebar
