import React from "react";
import Header from "../Header/Header";
import SideBar from "../SideBar";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

const OrderDetail = () => {
  return (
    <div>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div className="container">
            <div className="row">
              <div className="col-2">
                <SideBar />
              </div>
              <div className="col-10">
                <div
                  className="wrapper d-flex flex-column flex-row-fluid"
                  id="kt_wrapper"
                >
                  <Header />
                  <div
                    className="content d-flex flex-column flex-column-fluid ps-0 "
                    id="kt_content"
                  >
                    <section
                      class="h-100 h-custom"
                      style={{ backgroundCcolor: "#eee" }}
                    >
                      <div class="container py-5 h-100">
                        <div class="row d-flex justify-content-center align-items-center h-100">
                          <div class="col-lg-8 col-xl-10">
                            <div
                              class="card border-top border-bottom border-3"
                              style={{ borderColor: "#f37a27" }}
                            >
                              <div class="card-body p-5">
                                <p
                                  class="lead fw-bold mb-5"
                                  style={{ color: "#f37a27" }}
                                >
                                  Order Detail
                                </p>
                                <h1
                                  class="lead fw-bold mb-5 OrderRad"
                                  style={{}}
                                >
                                  Order
                                </h1>

                                <div class="row">
                                  {/* <div class="col mb-3">
                                    <p class="small text-muted mb-1">Date</p>
                                    <p>10 April 2021</p>
                                  </div> */}
                                  {/* <div class="col mb-3">
                                    <p class="small text-muted mb-1">
                                      Order No.
                                    </p>
                                    <p>012j1gvs356c</p>
                                  </div> */}
                                </div>

                                <div
                                  class="mx-n5 px-5 py-4"
                                  style={{ backgroundCcolor: "#f2f2f2" }}
                                >
                                  <div class="row">
                                    <div class="col-md-8 col-lg-9">
                                      {/* <p>BEATS Solo 3 Wireless Headphones</p> */}
                                    </div>
                                    {/* <div class="col-md-4 col-lg-3">
                                      <p>£299.99</p>
                                    </div> */}
                                  </div>
                                  {/* <div class="row my-4 py-4 offset-lg-5 mb-5">
                                    <div class="col-my-4 ">
                                      <h1 class="mb-0 fw-bold">
                                        CusTomer Addresss
                                      </h1>
                                    </div>
                                  </div> */}
                                </div>

                                <div class="row my-4 py-4 offset-lg-5 mb-5">
                                   <div class="row">
                                   <h1 class="mb-3 mb-0 fw-bold">CusTomer Addresss</h1>          

                                  <div class="col mb-3">
                                    <p class="mb-3 ">Name</p>
                                    <p class="mb-3">Last Name </p>
                                    <p class="mb-3">Email </p>
                                    <p class="mb-3">Address </p>
                                  </div>
                                  <div class="col mb-3">                  
                                    <p class="mb-3">John</p>
                                    <p class="mb-3">Britsh</p>
                                    <p class="mb-3">Mau </p>
                                    <p class="mb-3">Example@gmail.com </p>
                                    <p>
                                    <Link to="/editaddresss">
                                      <button className="StoreListing">
                                     Edit Addresss </button>
                                     </Link>
                                     </p>
                                  </div>
                                </div>
                                </div>

                                <div class="row">
                                  <div class="col mb-3">
                                    <h1
                                      class="col mb-3 fw-bold"
                                      style={{ fontSize: "20px" }}
                                    >
                                      Order Detail
                                    </h1>
                                    {/* <p class="small text-muted mb-1">Order ID</p> */}
                                    <p class="mb-3 ">Order ID</p>
                                    <p class="mb-3">Order </p>
                                    <p class="mb-3">Product Price </p>
                                    <p class="mb-3">Qty </p>
                                  </div>
                                  <div class="col mb-3">
                                    <h1
                                      class="col mb-3 fw-bold"
                                      style={{ fontSize: "20px" }}
                                    >
                                      Order Items
                                    </h1>
                                    <p class="mb-3">Product Name </p>
                                    <p class="mb-3">Product Price </p>
                                    <p class="mb-3">Qty </p>
                                    {/* <p>012j1gvs356c</p> */}
                                  </div>
                                </div>

                                {/* <p class="mt-4 pt-2 mb-0">
                                  Want any help?{" "}
                                  <a href="#!" style={{ color: "#f37a27" }}>
                                    Please contact us
                                  </a>
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default OrderDetail;
